import React from 'react';
import './App.css';
import $ from 'jquery';

function App() {
  const AWS = require('aws-sdk');
  const DEFAULT_NODE_LAMBDA_CODE = `
            var aws = require('aws-sdk');
            var response = require('cfn-response');
            exports.handler = async function (event, context) {
              console.log("EVENT: \\n" + JSON.stringify(event, null, 2));
              return context.logStreamName;
            };
  `;
  // https://github.com/aws-samples/aws-cloudformation-inline-python-lambda-example/blob/main/base-cfn-lambda.yml
  const CFN_TEMPLATE = `
  AWSTemplateFormatVersion: 2010-09-09
  Resources:
    LambdaRole:
      Type: AWS::IAM::Role
      Properties:
        RoleName: {0}LambdaRole
        Description: An execution role for a Lambda function launched by CloudFormation
        AssumeRolePolicyDocument:
          Version: '2012-10-17'
          Statement:
          - Effect: Allow
            Principal:
              Service: lambda.amazonaws.com
            Action:
            - 'sts:AssumeRole'
    {0}LambdaFunction:
      Type: 'AWS::Lambda::Function'
      Properties:
        Runtime: nodejs18.x
        Handler: index.handler
        MemorySize: 256
        Timeout: 240
        Role: !GetAtt 'LambdaRole.Arn'
        Code:
          ZipFile: |
            // Code goes here
            {1}
  `;
  /*
    {0}API:
      Type: 'AWS::ApiGatewayV2::Api'
      Properties: {}
*/
  function onLanguageSelected(event) {
    const selectedLanguage = event.target.selectedOptions[0].value;
    console.log("Language changed to " + selectedLanguage);
    if (selectedLanguage === 'node') {
      $('#apiCode').val(DEFAULT_NODE_LAMBDA_CODE);
    }
  }

  function setCreds() {
    AWS.config.update({
      region:'us-east-1',
      accessKeyId: $('#accessKey').val(),
      secretAccessKey: $('#secretKey').val()
    });
  }

  if (!String.prototype.format) {
    String.prototype.format = function() {
      var args = arguments;
      return this.replace(/{(\d+)}/g, function(match, number) {
        return typeof args[number] != 'undefined'
          ? args[number]
          : match
        ;
      });
    };
  }

  function createLambdaAPI() {
    setCreds();
    var cloudformation = new AWS.CloudFormation();
    var apiName = $('#apiName').val();
    var apiCode = $('#apiCode').val().replaceAll('\n', '            ');
    var params = {
      StackName: apiName,
      Capabilities: [ 'CAPABILITY_NAMED_IAM' ],
      EnableTerminationProtection: false,
      OnFailure: 'DELETE',
      TemplateBody: CFN_TEMPLATE.format(
        apiName,
        apiCode
      )
    };
    console.log(params.TemplateBody);
    cloudformation.createStack(params, function(err, data) {
      if (err) {
        console.log(err, err.stack);
      } else {
        console.log(data);
        return data.StackSummaries;
      }
    });
  }

  function getStacks() {
    setCreds();
    var cloudformation = new AWS.CloudFormation();
    cloudformation.listStacks({}, function(err, data) {
      if (err) {
        console.log(err, err.stack);
      } else {
        console.log(data);
        return data.StackSummaries;
      }
    });
  }

  return (
  <div className="text-bg-secondary p-3">
    <div className="App">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAbout" aria-expanded="true" aria-controls="collapseAbout">
              About
            </button>
          </h2>
          <div id="collapseAbout" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <div className="container">
                <ul>
                  If you have not created root credentials, then create root credentials from the AWS Console by following <a href='https://us-east-1.console.aws.amazon.com/iamv2/home#/security_credentials/access-key-wizard'>this link</a>.<br/>
                  You can use these credentials to create new Lambda-APIGateway APIs or view them using this portal.<br/>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDeploy" aria-expanded="false" aria-controls="collapseDeploy">
              Deploy
            </button>
          </h2>
          <div id="collapseDeploy" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <div className="container grid gap-0 row-gap-3">
                <div className="input-group p-2 g-col-6">
                  <span className="input-group-text">API Name</span>
                  <input type="text" id="apiName" className="form-control" placeholder="API Name" aria-label="APIName" />
                </div>
                <div className="input-group p-2 g-col-6">
                  <span className="input-group-text">Programming language</span>
                  <select className="form-select" aria-label="Programing Language" onChange={onLanguageSelected} defaultValue={"-"}>
                    <option value="-">-</option>
                    <option value="node">Node</option>
                    <option value="python">Python</option>
                  </select>
                </div>
                <div className="input-group p-2 g-col-6">
                  <span className="input-group-text">Lambda code</span>
                  <textarea className="form-control" aria-label="code here" rows="20" cols="100" id="apiCode"></textarea>
                </div>
                <div className="input-group p-2 g-col-6">
                  <span className="input-group-text">Access key</span>
                  <input type="text" id="accessKey" value="" className="form-control" placeholder="Access key" aria-label="AccessKey" />
                </div>
                <div className="input-group p-2 g-col-6">
                  <span className="input-group-text">Secret key</span>
                  <input type="text" id="secretKey" value="" className="form-control" placeholder="Secret key" aria-label="SecretKey" />
                </div>
                <div className="input-group p-2 g-col-6">
                  <button type="button" className="btn btn-primary p-2 g-col-6" onClick={createLambdaAPI}>Deploy</button>
                </div>
                <div className="input-group p-2 g-col-6">
                  <button type="button" className="btn btn-primary p-2 g-col-6" onClick={getStacks}>View</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default App;
